import { SessionReportInstructionType } from "@generated/graphql";
import { sectionContainerStyles } from "@utils/styleStrings";
import { clsx } from "clsx";
import {
  EmptyStateContainer,
  Icon,
  SelectMenu,
  SelectMenuOption,
  TextArea,
} from "components/shared";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import {
  flsLessonOptions,
  flsLevelsOptions,
  flsSkillCycleOptions,
  learningTargetOptions,
  off2ClassLessonOptions,
  priorityStandardOptions,
  strandOptions,
  ufliLessonOptions,
  ufliUnitsOptions,
} from "../../../../../constants";
import { SessionModalPageSubHeader } from "../../../../../helpers";
import {
  FlsLevel,
  InstructionData,
  Off2ClassLesson,
  UfliUnit,
  VIMElaPriorityStandards,
  VIMMathStrands,
  WIMMathLearningTargets,
} from "../../../../../types";
import {
  infoKeySectionStyles,
  infoSectionStyles,
  infoValueSectionStyles,
} from "./constants";

type Props = {
  readOnly: boolean;
  allStudentsAbsent: boolean;
  highlightIncomplete: boolean;
  instructionData: InstructionData;
  instructionType: SessionReportInstructionType;
  setInstructionData: (data: InstructionData) => void;
};

export const InstructionSection = ({
  readOnly,
  instructionData,
  instructionType,
  allStudentsAbsent,
  highlightIncomplete,
  setInstructionData,
}: Props) => {
  const updateData = useCallback(
    (data: Partial<InstructionData>) =>
      setInstructionData({ ...instructionData, ...data }),
    [instructionData, setInstructionData]
  );

  useEffect(() => {
    if (instructionData.ufliUnit === UfliUnit.FirstDayActivity)
      updateData({ ufliLesson: null });
    if (instructionData.flsLevel === FlsLevel.FirstDayActivity)
      updateData({ flsSkillCycle: null, flsLesson: null });
  }, [instructionData.flsLevel, instructionData.ufliUnit, updateData]);

  const renderTextArea = (): ReactNode => {
    const { instructionNotes } = instructionData;

    return readOnly ? (
      <div className={infoSectionStyles}>
        <div className={infoKeySectionStyles}>Instruction Notes</div>
        <div className={clsx("flex flex-wrap", infoValueSectionStyles)}>
          {instructionNotes ? (
            instructionNotes
          ) : (
            <div className="text-gray-300">No instruction notes...</div>
          )}
        </div>
      </div>
    ) : (
      <TextArea
        id="notes"
        label="Notes"
        value={instructionNotes ?? ""}
        textAreaClassName="min-h-[38px]"
        className="flex flex-1 grow"
        rows={!!instructionNotes ? 2 : 1}
        required={instructionType === SessionReportInstructionType.Default}
        onChange={(e) => updateData({ instructionNotes: e.target.value })}
      />
    );
  };

  const renderSelectMenu = (
    labelText: string,
    options: SelectMenuOption[],
    selectedValue: string | null,
    updateField: (value: ReactNode) => void,
    disable?: boolean
  ): ReactNode =>
    readOnly ? (
      <div className={infoSectionStyles}>
        <div className={infoKeySectionStyles}>{labelText}</div>
        <p className={infoValueSectionStyles}>{selectedValue}</p>
      </div>
    ) : (
      <SelectMenu
        options={options}
        disabled={disable}
        required={!disable}
        labelText={labelText}
        className="w-full md:w-[calc(50%-8px)] lg:w-[302px]"
        inputClassName={clsx(
          !disable &&
            highlightIncomplete &&
            selectedValue === null &&
            "border-2 border-red-500"
        )}
        initialIndex={
          disable
            ? 0
            : options.findIndex((option) => option.value === selectedValue)
        }
        onSelect={({ value }) => updateField(value === "--" ? null : value)}
      />
    );

  const instructionContent = useMemo(() => {
    const components: ReactNode[] = [];
    const { strand, learningTarget, classCode } = instructionData;

    switch (instructionType) {
      case SessionReportInstructionType.VimMath:
        components.push(
          renderSelectMenu("Strand", strandOptions, strand, (value) =>
            updateData({ strand: value as VIMMathStrands })
          )
        );
        components.push(
          renderSelectMenu(
            "Learning Target",
            learningTargetOptions,
            learningTarget,
            (value) =>
              updateData({ learningTarget: value as WIMMathLearningTargets })
          )
        );
        break;
      case SessionReportInstructionType.VimEla:
        components.push(
          renderSelectMenu(
            "Priority Standard",
            priorityStandardOptions,
            instructionData.priorityStandard,
            (value) =>
              updateData({ priorityStandard: value as VIMElaPriorityStandards })
          )
        );
        break;
      case SessionReportInstructionType.Off2Class:
        components.push(
          renderSelectMenu(
            "Lesson",
            off2ClassLessonOptions,
            instructionData.off2ClassLesson,
            (value) => updateData({ off2ClassLesson: value as Off2ClassLesson })
          )
        );
        break;
      case SessionReportInstructionType.Ufli:
        components.push(
          renderSelectMenu(
            "Unit",
            ufliUnitsOptions,
            instructionData.ufliUnit,
            (value) => updateData({ ufliUnit: value as UfliUnit })
          )
        );
        components.push(
          renderSelectMenu(
            "Lesson",
            ufliLessonOptions,
            instructionData.ufliLesson,
            (value) => updateData({ ufliLesson: value as string }),
            instructionData.ufliUnit === UfliUnit.FirstDayActivity
          )
        );
        break;
      case SessionReportInstructionType.Fls:
        components.push(
          renderSelectMenu(
            "Level",
            flsLevelsOptions,
            instructionData.flsLevel,
            (value) => updateData({ flsLevel: value as FlsLevel })
          )
        );
        components.push(
          renderSelectMenu(
            "Skill Cycle",
            flsSkillCycleOptions,
            instructionData.flsSkillCycle,
            (value) => updateData({ flsSkillCycle: value as string }),
            instructionData.flsLevel === FlsLevel.FirstDayActivity
          )
        );
        components.push(
          renderSelectMenu(
            "Lesson",
            flsLessonOptions,
            instructionData.flsLesson,
            (value) => updateData({ flsLesson: value as string }),
            instructionData.flsLevel === FlsLevel.FirstDayActivity
          )
        );
        break;
      case SessionReportInstructionType.Vsup:
        components.push(
          readOnly ? (
            <div className={infoSectionStyles}>
              <p className={infoKeySectionStyles}>Class Code:</p>
              <p className={infoValueSectionStyles}>{classCode}</p>
            </div>
          ) : (
            <TextArea
              rows={1}
              required
              id="classCode"
              label="Class Code"
              className="w-[300px]"
              value={classCode ?? ""}
              textAreaClassName={clsx(
                "min-h-[38px] max-h-[38px]",
                highlightIncomplete &&
                  classCode === null &&
                  "border-2 border-red-500"
              )}
              onChange={(e) => updateData({ classCode: e.target.value })}
            />
          )
        );
      default:
        break;
    }

    return components;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionData, instructionType, highlightIncomplete]);

  return (
    <div
      className={clsx("flex flex-col gap-y-3 z-300", sectionContainerStyles)}
    >
      <SessionModalPageSubHeader
        title="Instruction"
        description={
          readOnly || allStudentsAbsent
            ? undefined
            : instructionType === SessionReportInstructionType.Vsup
            ? "Please enter your class code and provide a summary of what you taught in class today."
            : "What did you focus on in class today?"
        }
      />
      {allStudentsAbsent ? (
        <EmptyStateContainer
          title="No Instruction to Track"
          icon={<Icon icon="session" color="text-cyan-800" size={7} />}
          className="max-h-[120px]"
          subtitle="No students attended this session."
        />
      ) : (
        <div
          className={clsx(
            "flex flex-col w-full",
            readOnly ? "gap-y-[2px]" : "gap-y-1.5"
          )}
        >
          <div
            className={clsx(
              "flex gap-x-4 flex-wrap gap-y-1",
              readOnly ? "flex-col gap-y-[2px]" : "flex-row"
            )}
          >
            {instructionContent.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </div>
          {renderTextArea()}
        </div>
      )}
    </div>
  );
};
