import clsx from "clsx";

export const SessionModalPageHeader = ({
  title,
  description,
  className,
}: {
  title: string;
  description: string;
  className?: string;
}) => (
  <div className={clsx("flex flex-col mt-4 mb-1 gap-y-[6px] pl-1", className)}>
    <label className="text-[26px] text-slate-700 font-extrabold leading-none">
      {title}
    </label>
    <p className="text-sm text-slate-600 font-semibold leading-none ml-px">
      {description}
    </p>
  </div>
);

export const SessionModalPageSubHeader = ({
  title,
  description,
  className,
}: {
  title: string;
  description?: string;
  className?: string;
}) => (
  <div className={clsx("flex flex-col gap-y-[6px]", className)}>
    <label className="text-[22px] text-cyan-900 font-extrabold leading-none">
      {title}
    </label>
    {description && (
      <p className="text-sm text-slate-600 font-medium leading-none ml-px">
        {description}
      </p>
    )}
  </div>
);
