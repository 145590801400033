import { SessionConcernType } from "@generated/graphql";
import { getSessionConcernTypeText } from "@utils/labels";
import { SelectMenuOption } from "components/shared";
import {
  FlsLevel,
  InstructionData,
  Off2ClassLesson,
  SessionConcernData,
  UfliUnit,
  VIMElaPriorityStandards,
  VIMMathStrands,
  WIMMathLearningTargets,
} from "./types";
import { getNumberSequenceStrings } from "./utils";

export const cellStyles = "flex items-center px-4 py-2";

export const initInstructionData: InstructionData = {
  strand: null,
  flsLevel: null,
  ufliUnit: null,
  classCode: null,
  flsLesson: null,
  ufliLesson: null,
  flsSkillCycle: null,
  learningTarget: null,
  off2ClassLesson: null,
  priorityStandard: null,
  instructionNotes: null,
};

export const initConcernData: SessionConcernData = {
  hasConcerns: null,
  concerns: [],
};

export const strandOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(VIMMathStrands).map(([strand, value]) => {
    return { id: strand, value };
  }),
];

export const learningTargetOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(WIMMathLearningTargets).map(([target, value]) => {
    return { id: target, value };
  }),
];

export const priorityStandardOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(VIMElaPriorityStandards).map(([standard, value]) => {
    return { id: standard, value };
  }),
];

export const concernTypeOptions: SelectMenuOption<{
  concern: SessionConcernType | null;
}>[] = [
  { id: "NONE", value: "--", concern: null },
  ...Object.values(SessionConcernType).map((concern) => {
    const value = getSessionConcernTypeText(concern);
    return { id: value, value, concern };
  }),
];

export const flsCycleOptionStrings = getNumberSequenceStrings(0, 26);
export const flsLessonOptionStrings = getNumberSequenceStrings(0, 150);

export const flsLevelsOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(FlsLevel).map(([id, value]) => ({ id, value })),
];

export const flsSkillCycleOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...flsCycleOptionStrings.map((value) => ({ id: value, value })),
];

export const flsLessonOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...flsLessonOptionStrings.map((value) => ({ id: value, value })),
];

export const off2ClassLessonOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(Off2ClassLesson).map(([id, value]) => ({ id, value })),
];

export const ufliLessonOptionStrings = [
  ...getNumberSequenceStrings(1, 34),
  "35a",
  "35b",
  "35c",
  "36a",
  "36b",
  "37a",
  "37b",
  "38a",
  "38b",
  "39a",
  "39b",
  "40a",
  "40b",
  "41a",
  "41b",
  "41c",
  ...getNumberSequenceStrings(42, 66),
  "67a",
  "67b",
  ...getNumberSequenceStrings(68, 128),
];

export const ufliUnitsOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(UfliUnit).map(([id, value]) => ({ id, value })),
];

export const ufliLessonOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...ufliLessonOptionStrings.map((value) => ({ id: value, value })),
];
